<router-outlet></router-outlet>
@if (version) {
  <div
    style="
      position: fixed;
      right: 24px;
      bottom: 0;
      z-index: 10;
      font-size: 4pt;
      color: gray;
    ">
    {{ version }}
  </div>
}
