import { Routes } from "@angular/router";

export const landingRoutes: Routes = [
    {
        path: '', loadComponent: () => import('../../shared/components/theme/theme-sidebar/theme-sidebar.component').then(c => c.ThemeSidebarComponent), children: [
            {
                path: '',
                loadComponent: () => import('./landing-main/landing-main.component').then(c => c.LandingMainComponent)
            }
        ]
    },
]