/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { EmployeeActions } from './employee.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { Employee } from 'src/app/shared/models';

@Injectable()
export class EmployeeEffects {
  loadEmployees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeActions.loadEmployees),
      mergeMap(() => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'employees'),
            snapshot => {
              const employees = snapshot.docs.map(doc =>
                Employee.fromJSON({ ...doc.data(), id: doc.id })
              );
              subscriber.next(
                EmployeeActions.loadEmployeesSuccess({ employees })
              );
            },
            error => {
              subscriber.next(EmployeeActions.loadEmployeesFailure({ error }));
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Employee API] Load Employees Error', error })
          )
        );
      })
    );
  });

  loadEmployee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeActions.loadEmployee),
      mergeMap(({ employeeId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'employees', employeeId),
            snapshot => {
              const employee = Employee.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              subscriber.next(
                EmployeeActions.loadEmployeeSuccess({ employee })
              );
            },
            error => {
              subscriber.next(EmployeeActions.loadEmployeeFailure({ error }));
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addEmployee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeActions.addEmployee),
      mergeMap(async ({ employee }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'employees'),
            Employee.toJSON(employee) as any
          );
          return EmployeeActions.addEmployeeSuccess({
            employee: { ...employee, id: docRef.id },
          }); // return new employee with id
        } catch (error) {
          return EmployeeActions.addEmployeeFailure({ error: error as Error });
        }
      })
    );
  });

  removeEmployee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeActions.removeEmployee),
      mergeMap(async ({ employeeId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'employees', employeeId));
          return EmployeeActions.removeEmployeeSuccess({ employeeId }); // return removed employee's id
        } catch (error) {
          return EmployeeActions.removeEmployeeFailure({
            error: error as Error,
          });
        }
      })
    );
  });

  updateEmployee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeActions.updateEmployee),
      mergeMap(async ({ employeeId, employee }) => {
        try {
          await setDoc(
            doc(this.firestore, 'employees', employeeId),
            Employee.toJSON(employee) as any,
            {
              merge: true,
            }
          );
          return EmployeeActions.updateEmployeeSuccess({
            employeeId,
            employee,
          }); // return updated employee's id and changes
        } catch (error) {
          return EmployeeActions.updateEmployeeFailure({
            error: error as Error,
          });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) { }
}
