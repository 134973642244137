import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth, authState } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { getWindow } from 'ssr-window'

@Injectable()
export class AuthGuard {
  constructor(
    private auth: Auth,
  ) { }
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(resolve => {
      authState(this.auth).subscribe(user => {
        if (user === null) {
          const window = getWindow()
          window.location.href = environment.enrollhereBaseUrl + '?redirect=importer';
        }
        resolve(user !== null);
      });
    });
  }
}
