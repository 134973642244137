import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ImporterImportActions } from './importer-import.actions';
import {
  DocumentData,
  Firestore,
  Query,
  QueryDocumentSnapshot,
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from '@angular/fire/firestore';
import {
  ImporterImport,
  importerImport_StatusToJSON,
} from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class ImporterImportEffects {
  last: QueryDocumentSnapshot<DocumentData, DocumentData> | null = null;

  loadImporterImports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterImportActions.loadImporterImports),
      mergeMap(({ userId, importerChannelId, from, size, term, status }) => {
        return new Observable<Action>(subscriber => {
          let q: Query<DocumentData, DocumentData> = collection(
            this.firestore,
            'users',
            userId,
            'importerChannels',
            importerChannelId,
            'imports'
          );

          if (size) {
            q = query(q, limit(size));
          }

          if (term) {
            q = query(
              q,
              where('name', '>=', term),
              where('name', '<=', term + '\uf8ff')
            );
          }

          if (status) {
            q = query(
              q,
              where('status', '==', importerImport_StatusToJSON(status))
            );
          }

          q = query(q, orderBy('firstTryStarted', 'desc'));

          if (from && this.last) {
            q = query(q, startAfter(this.last));
            // q = query(q, startAfter(from));
          }

          const unsubscribe = onSnapshot(
            q,
            snapshot => {
              const importerImports = snapshot.docs.map(doc =>
                ImporterImport.fromJSON({
                  ...doc.data(),
                  id: doc.id,
                  userId,
                  importerChannelId,
                })
              );

              subscriber.next(
                ImporterImportActions.loadImporterImportsSuccess({
                  importerImports,
                })
              );

              this.last = snapshot.docs?.length
                ? snapshot.docs[snapshot.docs.length - 1]
                : null;
            },
            error => {
              subscriber.next(
                ImporterImportActions.loadImporterImportsFailure({ error })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({
              type: '[ImporterImport API] Load ImporterImports Error',
              error,
            })
          )
        );
      })
    );
  });

  loadImporterImport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterImportActions.loadImporterImport),
      mergeMap(({ importerImportId, importerChannelId, userId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(
              this.firestore,
              'users',
              userId,
              'importerChannels',
              importerImportId
            ),
            snapshot => {
              const importerImport = ImporterImport.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                importerChannelId,
                userId,
              });
              subscriber.next(
                ImporterImportActions.loadImporterImportSuccess({
                  importerImport,
                })
              );
            },
            error => {
              console.error(error);
              subscriber.next(
                ImporterImportActions.loadImporterImportFailure({ error })
              );
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
