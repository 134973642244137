import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CommissionCycleActions } from './commission-cycle.actions';
import {
  DocumentData,
  Firestore,
  Query,
  addDoc,
  collection,
  deleteDoc,
  doc,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
} from '@angular/fire/firestore';
import { CommissionCycle } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class CommissionCycleEffects {
  loadCommissionCycles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionCycleActions.loadCommissionCycles),
      mergeMap(({ userId, from, size, term }) => {
        return new Observable<Action>(subscriber => {
          let q: Query<DocumentData, DocumentData> = collection(
            this.firestore,
            'agencies',
            userId,
            'commissionCycles'
          );

          if (from) {
            q = query(q, startAfter(from));
          }

          if (size) {
            q = query(q, limit(size));
          }

          if (term) {
            q = query(
              q,
              where('name', '>=', term),
              where('name', '<=', term + '\uf8ff')
            );
          }

          q = query(q, orderBy('name'));

          const unsubscribe = onSnapshot(
            q,
            snapshot => {
              const commissionCycle = snapshot.docs.map(doc =>
                CommissionCycle.fromJSON({
                  ...doc.data(),
                  id: doc.id,
                  userId,
                })
              );
              subscriber.next(
                CommissionCycleActions.loadCommissionCyclesSuccess({
                  commissionCycle,
                })
              );
            },
            error => {
              subscriber.next(
                CommissionCycleActions.loadCommissionCyclesFailure({ error })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({
              type: '[CommissionCycle API] Load CommissionCycles Error',
              error,
            })
          )
        );
      })
    );
  });

  loadCommissionCycle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionCycleActions.loadCommissionCycle),
      mergeMap(({ commissionCycleId, userId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(
              this.firestore,
              'agencies',
              userId,
              'commissionCycles',
              commissionCycleId
            ),
            snapshot => {
              const commissionCycle = CommissionCycle.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                userId,
              });
              subscriber.next(
                CommissionCycleActions.loadCommissionCycleSuccess({
                  commissionCycle,
                })
              );
            },
            error => {
              subscriber.next(
                CommissionCycleActions.loadCommissionCycleFailure({ error })
              );
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addCommissionCycle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionCycleActions.addCommissionCycle),
      mergeMap(async ({ userId, commissionCycle }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies', userId, 'commissionCycles'),
            CommissionCycle.toJSON(commissionCycle)
          );
          console.log('doc added', docRef);
          return CommissionCycleActions.addCommissionCycleSuccess({
            commissionCycle: CommissionCycle.fromJSON({
              ...commissionCycle,
              id: docRef.id,
              userId,
            }),
          }); // return new commissionCycle with id
        } catch (error) {
          return CommissionCycleActions.addCommissionCycleFailure({ error });
        }
      })
    );
  });

  removeCommissionCycle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionCycleActions.removeCommissionCycle),
      mergeMap(async ({ userId, commissionCycleId }) => {
        try {
          await deleteDoc(
            doc(
              this.firestore,
              'agencies',
              userId,
              'commissionCycles',
              commissionCycleId
            )
          );
          return CommissionCycleActions.removeCommissionCycleSuccess({
            commissionCycleId,
          }); // return removed commissionCycle's id
        } catch (error) {
          return CommissionCycleActions.removeCommissionCycleFailure({ error });
        }
      })
    );
  });

  updateCommissionCycle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionCycleActions.updateCommissionCycle),
      mergeMap(async ({ userId, commissionCycleId, commissionCycle }) => {
        try {
          await setDoc(
            doc(
              this.firestore,
              'agencies',
              userId,
              'commissionCycles',
              commissionCycleId
            ),
            {
              ...(CommissionCycle.toJSON(commissionCycle) as any),
              currentVersion: increment(1),
            },
            {
              merge: true,
            }
          );
          return CommissionCycleActions.updateCommissionCycleSuccess({
            userId,
            commissionCycleId,
            commissionCycle,
          }); // return updated commissionCycle's id and changes
        } catch (error) {
          console.error('updateCommissionCycle error', error);
          return CommissionCycleActions.updateCommissionCycleFailure({ error });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
