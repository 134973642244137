import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { WebhookChannelActions } from './webhook-channel.actions';
import {
  DocumentData,
  Firestore,
  Query,
  addDoc,
  collection,
  deleteDoc,
  doc,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
} from '@angular/fire/firestore';
import {
  WebhookChannel,
  webhookChannel_SourceToJSON,
  typeToJSON,
} from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class WebhookChannelEffects {
  loadWebhookChannels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WebhookChannelActions.loadWebhookChannels),
      mergeMap(
        ({ userId, from, size, term, webhookSource, webhookChannelType }) => {
          return new Observable<Action>(subscriber => {
            let q: Query<DocumentData, DocumentData> = collection(
              this.firestore,
              'users',
              userId,
              'webhookChannels'
            );

            if (from) {
              q = query(q, startAfter(from));
            }

            if (size) {
              q = query(q, limit(size));
            }

            if (webhookSource) {
              q = query(
                q,
                where(
                  'source',
                  '==',
                  webhookChannel_SourceToJSON(webhookSource)
                )
              );
            }
            if (webhookChannelType) {
              q = query(q, where('type', '==', typeToJSON(webhookChannelType)));
            }

            if (term) {
              q = query(
                q,
                where('name', '>=', term),
                where('name', '<=', term + '\uf8ff')
              );
            }

            q = query(q, orderBy('name'));

            const unsubscribe = onSnapshot(
              q,
              snapshot => {
                const webhookChannels = snapshot.docs.map(doc =>
                  WebhookChannel.fromJSON({
                    ...doc.data(),
                    id: doc.id,
                    userId,
                  })
                );
                subscriber.next(
                  WebhookChannelActions.loadWebhookChannelsSuccess({
                    webhookChannels,
                  })
                );
              },
              error => {
                console.log('loadWebhookChannels error', error);
                subscriber.next(
                  WebhookChannelActions.loadWebhookChannelsFailure({ error })
                );
              }
            );

            // Provide a way of canceling and disposing the listener
            return unsubscribe;
          }).pipe(
            catchError(error =>
              of({
                type: '[WebhookChannel API] Load WebhookChannels Error',
                error,
              })
            )
          );
        }
      )
    );
  });

  loadWebhookChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WebhookChannelActions.loadWebhookChannel),
      mergeMap(({ webhookChannelId, userId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(
              this.firestore,
              'users',
              userId,
              'webhookChannels',
              webhookChannelId
            ),
            snapshot => {
              console.log('GOT CHANNEL', snapshot);
              const webhookChannel = WebhookChannel.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                userId,
              });
              subscriber.next(
                WebhookChannelActions.loadWebhookChannelSuccess({
                  webhookChannel,
                })
              );
            },
            error => {
              subscriber.next(
                WebhookChannelActions.loadWebhookChannelFailure({ error })
              );
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addWebhookChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WebhookChannelActions.addWebhookChannel),
      mergeMap(async ({ userId, webhookChannel }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'users', userId, 'webhookChannels'),
            WebhookChannel.toJSON(webhookChannel)
          );
          console.log('doc added', docRef);
          return WebhookChannelActions.addWebhookChannelSuccess({
            webhookChannel: WebhookChannel.fromJSON({
              ...webhookChannel,
              id: docRef.id,
              userId,
            }),
          }); // return new webhookChannel with id
        } catch (error) {
          return WebhookChannelActions.addWebhookChannelFailure({ error });
        }
      })
    );
  });

  removeWebhookChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WebhookChannelActions.removeWebhookChannel),
      mergeMap(async ({ userId, webhookChannelId }) => {
        try {
          await deleteDoc(
            doc(
              this.firestore,
              'users',
              userId,
              'webhookChannels',
              webhookChannelId
            )
          );
          return WebhookChannelActions.removeWebhookChannelSuccess({
            webhookChannelId,
          }); // return removed webhookChannel's id
        } catch (error) {
          return WebhookChannelActions.removeWebhookChannelFailure({ error });
        }
      })
    );
  });

  updateWebhookChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WebhookChannelActions.updateWebhookChannel),
      mergeMap(async ({ userId, webhookChannelId, webhookChannel }) => {
        console.log('updateWebhookChannel', userId, webhookChannelId, webhookChannel);
        try {
          await setDoc(
            doc(
              this.firestore,
              'users',
              userId,
              'webhookChannels',
              webhookChannelId
            ),
            {
              ...(WebhookChannel.toJSON(webhookChannel) as any),
              currentVersion: increment(1),
            },
            {
              merge: true,
            }
          );
          return WebhookChannelActions.updateWebhookChannelSuccess({
            userId,
            webhookChannelId,
            webhookChannel,
          }); // return updated webhookChannel's id and changes
        } catch (error) {
          console.error('updateWebhookChannel error', error);
          return WebhookChannelActions.updateWebhookChannelFailure({ error });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
