import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CarrierActions } from './carrier.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Carrier } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class CarrierEffects {
  loadCarriers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierActions.loadCarriers),
      mergeMap(() => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'carriers'),
            snapshot => {
              const carriers = snapshot.docs.map(doc =>
                Carrier.fromJSON({ ...doc.data(), id: doc.id })
              );
              subscriber.next(CarrierActions.loadCarriersSuccess({ carriers }));
            },
            error => {
              subscriber.next(CarrierActions.loadCarriersFailure({ error }));
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Carrier API] Load Carriers Error', error })
          )
        );
      })
    );
  });

  loadCarrier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierActions.loadCarrier),
      mergeMap(({ carrierId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'carriers', carrierId),
            snapshot => {
              const carrier = Carrier.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              subscriber.next(CarrierActions.loadCarrierSuccess({ carrier }));
            },
            error => {
              subscriber.next(CarrierActions.loadCarrierFailure({ error }));
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addCarrier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierActions.addCarrier),
      mergeMap(async ({ carrier }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'carriers'),
            carrier
          );
          return CarrierActions.addCarrierSuccess({
            carrier: Carrier.fromJSON({ ...carrier, id: docRef.id }),
          }); // return new carrier with id
        } catch (error) {
          return CarrierActions.addCarrierFailure({ error });
        }
      })
    );
  });

  removeCarrier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierActions.removeCarrier),
      mergeMap(async ({ carrierId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'carriers', carrierId));
          return CarrierActions.removeCarrierSuccess({ carrierId }); // return removed carrier's id
        } catch (error) {
          return CarrierActions.removeCarrierFailure({ error });
        }
      })
    );
  });

  updateCarrier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarrierActions.updateCarrier),
      mergeMap(async ({ carrierId, carrier }) => {
        try {
          await setDoc(doc(this.firestore, 'carriers', carrierId), carrier, {
            merge: true,
          });
          return CarrierActions.updateCarrierSuccess({
            carrierId,
            carrier,
          }); // return updated carrier's id and changes
        } catch (error) {
          return CarrierActions.updateCarrierFailure({ error });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) { }
}
