import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ImporterChannelActions } from './import-channel.actions';
import {
  DocumentData,
  Firestore,
  Query,
  addDoc,
  collection,
  deleteDoc,
  doc,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
} from '@angular/fire/firestore';
import {
  ImporterChannel,
  importerChannel_SourceToJSON,
  typeToJSON,
} from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class ImporterChannelEffects {
  loadImporterChannels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterChannelActions.loadImporterChannels),
      mergeMap(
        ({ userId, from, size, term, importerSource, importerChannelType }) => {
          return new Observable<Action>(subscriber => {
            let q: Query<DocumentData, DocumentData> = collection(
              this.firestore,
              'users',
              userId,
              'importerChannels'
            );

            if (from) {
              q = query(q, startAfter(from));
            }

            if (size) {
              q = query(q, limit(size));
            }

            if (importerSource) {
              q = query(
                q,
                where(
                  'source',
                  '==',
                  importerChannel_SourceToJSON(importerSource)
                )
              );
            }
            if (importerChannelType) {
              q = query(
                q,
                where(
                  'type',
                  '==',
                  typeToJSON(importerChannelType)
                )
              );
            }

            if (term) {
              q = query(
                q,
                where('name', '>=', term),
                where('name', '<=', term + '\uf8ff')
              );
            }

            q = query(q, orderBy('name'));

            const unsubscribe = onSnapshot(
              q,
              snapshot => {
                const importerChannels = snapshot.docs.map(doc =>
                  ImporterChannel.fromJSON({
                    ...doc.data(),
                    id: doc.id,
                    userId,
                  })
                );
                subscriber.next(
                  ImporterChannelActions.loadImporterChannelsSuccess({
                    importerChannels,
                  })
                );
              },
              error => {
                console.log('loadImporterChannels error', error);
                subscriber.next(
                  ImporterChannelActions.loadImporterChannelsFailure({ error })
                );
              }
            );

            // Provide a way of canceling and disposing the listener
            return unsubscribe;
          }).pipe(
            catchError(error =>
              of({
                type: '[ImporterChannel API] Load ImporterChannels Error',
                error,
              })
            )
          );
        }
      )
    );
  });

  loadImporterChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterChannelActions.loadImporterChannel),
      mergeMap(({ importerChannelId, userId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(
              this.firestore,
              'users',
              userId,
              'importerChannels',
              importerChannelId
            ),
            snapshot => {
              const importerChannel = ImporterChannel.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                userId,
              });
              subscriber.next(
                ImporterChannelActions.loadImporterChannelSuccess({
                  importerChannel,
                })
              );
            },
            error => {
              subscriber.next(
                ImporterChannelActions.loadImporterChannelFailure({ error })
              );
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addImporterChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterChannelActions.addImporterChannel),
      mergeMap(async ({ userId, importerChannel }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'users', userId, 'importerChannels'),
            ImporterChannel.toJSON(importerChannel)
          );
          console.log('doc added', docRef);
          return ImporterChannelActions.addImporterChannelSuccess({
            importerChannel: ImporterChannel.fromJSON({
              ...importerChannel,
              id: docRef.id,
              userId,
            }),
          }); // return new importerChannel with id
        } catch (error) {
          return ImporterChannelActions.addImporterChannelFailure({ error });
        }
      })
    );
  });

  removeImporterChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterChannelActions.removeImporterChannel),
      mergeMap(async ({ userId, importerChannelId }) => {
        try {
          await deleteDoc(
            doc(
              this.firestore,
              'users',
              userId,
              'importerChannels',
              importerChannelId
            )
          );
          return ImporterChannelActions.removeImporterChannelSuccess({
            importerChannelId,
          }); // return removed importerChannel's id
        } catch (error) {
          return ImporterChannelActions.removeImporterChannelFailure({ error });
        }
      })
    );
  });

  updateImporterChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterChannelActions.updateImporterChannel),
      mergeMap(async ({ userId, importerChannelId, importerChannel }) => {
        try {
          await setDoc(
            doc(
              this.firestore,
              'users',
              userId,
              'importerChannels',
              importerChannelId
            ),
            {
              ...(ImporterChannel.toJSON(importerChannel) as any),
              currentVersion: increment(1),
            },
            {
              merge: true,
            }
          );
          return ImporterChannelActions.updateImporterChannelSuccess({
            userId,
            importerChannelId,
            importerChannel,
          }); // return updated importerChannel's id and changes
        } catch (error) {
          console.error('updateImporterChannel error', error);
          return ImporterChannelActions.updateImporterChannelFailure({ error });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
